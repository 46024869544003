@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap");

@font-face {
  font-family: "jost";
  src: local("MyFont"), url(./Assists/fonts/one.ttf);
}
@font-face {
  font-family: "jost-bold";
  src: local("MyFont"), url(./Assists/fonts/two.ttf);
}
.but {
  color: #162138;
}

* {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  font-family: "Jost", sans-serif !important;
}

.App {
  text-align: center;
  overflow: hidden;
}

body {
  overflow: hidden;
}
.video-react-has-started .video-react-control-bar {
  display: none !important;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.1s, opacity 0.1s;
}
