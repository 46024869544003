.active {
  border: 2px solid black !important;
  background: rgb(0 0 0 / 20%) !important;
}

.h-100 {
  height: 100vh !important;
}

.c > div {
  height: 100vh !important;
  padding-top: 0 !important;
  width: 100vw !important;
}

.flex {
  display: flex;
  align-items: center;
}

.ball {
  transition: all 300ms;
  /* animation: bounce 600ms infinite; */
}
.box {
  transition: all 300ms;
}

#container-circles {
  display: none;
}

.react-html5-camera-photo-fullscreen > video {
  object-fit: cover !important;
}

.c video {
  object-fit: cover !important;
}

.video-react-big-play-button {
  display: none !important;
}

.custom_box {
  display: flex;
  min-height: 99vh;
  align-items: center;
}

.custom_box video {
  transform: rotateY(180deg) translateX(38vw) !important;
  display: inline-block !important;
  min-width: 201vw !important;
  height: 100vh !important;
}
.react-html5-camera-photo img {
  transform: translateX(-41vw) scale(0.8) translateY(-35vw);
}
.audio {
  display: none;
}

.ideo-react-control-bar-auto-hide {
  display: none !important;
}
.video-react-control-bar {
  display: none !important;
}
.video-react-has-started .video-react-control-bar {
  display: none !important;
}
